.top-bar {
  display: flex;
  padding: 10px;
  border-bottom: 3px solid #000;
  align-items: center;
}

.top-bar__title h1 {
  margin: 0;
  padding: 0;
}

.top-bar__sign-out {
  margin-left: auto;
  font-size: 32px;
  cursor: pointer;
}

.top-bar__environment {
  display: flex;
  margin-left: 25px;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  background-color: #ccc;
}

.top-bar__environment__icon {
  font-size: 16px;
  padding-right: 10px;
}

.top-bar__environment__text {
  font-weight: bold;
}

.top-bar__title,
.top-bar__environment,
.top-bar__sign-out {

}