.report-template__container {
  display: flex;
}

.report-template__container textarea {
  border: solid 3px #000;
  flex-grow: 1;
  min-height: 400px;
}

.report-template__use-custom-section {
  margin-bottom: 10px;
}

.report-template__buttons {
  margin: 10px 0;
}

.report-template__error {
  margin: 10px 0;
  padding: 10px;
  font-size: 1.4em;
  border: solid 3px #f00;
  display: flex;
  align-items: center;
}

.report-template__error__icon {
  font-size: 42px;
  margin-right: 10px;
  color: #f00;
}
