.menu-bar {
  padding: 10px;
  background-color: #ddd;
  border-bottom: 1px solid #000;
}

.menu-bar__items {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.menu-bar__items__item {
  display: flex;
  margin-right: 20px;
  padding: 5px;
  align-items: center;
  cursor: pointer;
}

.menu-bar__items__item a {
  display: flex;
  flex-grow: 1;
  text-decoration: none;
  color: #000;
  align-items: center;
}

.menu-bar__items__item__icon {
  font-size: 24px;
  margin-right: 10px;
}

.menu-bar__items__item__icon {
  font-size: 1.2em;
}