.responsive-table {
  list-style: none;
  padding: 0;
}

.responsive-table__row {
  padding: 5px;
  border: 3px solid #000;
  display: flex;
  align-content: center;
  margin-bottom: 5px;
  min-height: 70px;
}

.responsive-table__row a {
  display: flex;
  align-content: center;
  text-decoration: none;
  color: #000;
  flex-grow: 1;
}

.responsive-table__row__icons {
  font-size: 25px;
  padding: 10px;
  margin-right: 10px;
  width: 30px;
  text-align: center;
  align-self: center;
}

.responsive-table__row__column {
  padding: 0 10px 0 0;
  font-size: 1.1em;
  align-self: center;
  width: 180px;
  display: flex;
  align-items: center;
}

.responsive-table__row__column__icon {
  margin-right: 10px;
  font-size: 24px;
}

.responsive-table__row__column__icon,
.responsive-table__row__column__text {
}

.responsive-table__row__column__text {
  flex-grow: 1;
}

.responsive-table__row__buttons {
  margin-left: auto;
  min-width: 110px;
  align-self: center;
  padding-right: 5px;
}

.responsive-table__row__icon,
.responsive-table__row__column,
.responsive-table__row__buttons { 

}

.responsive-table.list-mode a,
.responsive-table.minimal-mode a {
  text-decoration: none;
}

.responsive-table.list-mode .responsive-table__row,
.responsive-table.minimal-mode .responsive-table__row {
  display: block;
  position: relative;
}

.responsive-table.list-mode .responsive-table__row__column,
.responsive-table.minimal-mode .responsive-table__row__column {
  margin-left: 70px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.responsive-table.list-mode .responsive-table__row__icons,
.responsive-table.minimal-mode .responsive-table__row__icons {
  float: left;
  font-size: 35px;
  padding: 10px;
  margin-right: 10px;
  width: 40px;
}

.responsive-table.list-mode .responsive-table__row__buttons,
.responsive-table.minimal-mode .responsive-table__row__buttons {
  top: 10px;
  right: 10px;
  position: absolute;
}

.responsive-table__row__header-column {
  font-size: 1.6em;
}

.responsive-table.minimal-mode .responsive-table__row__buttons {
  display: none;
}

.responsive-table__paging-info {
  display: flex;
}

.responsive-table__paging-info__refresh {
  font-size: 32px;
  margin: 0 15px 0 auto;
  align-self: center;
  cursor: pointer;
}

.responsive-table__load-more {
  font-size: 1.5em;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  height: 70px;
}


.responsive-table__load-more .loading-wrapper {
  margin-top: 0;
  padding: 26px;
  align-self: start;
}

.responsive-table__load-more__icon {
  margin-right: 10px;
}
