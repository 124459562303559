.button {
  font-size: 1.2em;
  background-color: var(--color-blue);
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  text-align: left;
  border: 0;
  color: #fff;
  white-space: nowrap;
}

.button.destructive {
  background-color: #FF852C;
}

.button.submit {
  background-color: #3F6937;
}

.button__icon, .button__text {
  display: inline-block;
}

.button__icon {
  margin-right: 10px;
  width: 25px;
  text-align: center;
}

.button__icon__animated {
  animation: rotation 2s infinite linear;
}
