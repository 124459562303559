.input-field__label {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  border: solid 3px #000;
  font-size: 1.3em;
  display: flex;;
}

.input-field__label input {
  font-size: 1em;
}

.input-field__label__text {
  min-width: 250px;
}
